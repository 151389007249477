import React, { useMemo } from "react";
import SubMenuGroup from "../sub-menu-group/SubMenuGroup";
import classNames from "classnames";

const SubMenuItem = ({
  navData: { children, assetID, pageName, pageURL },
  callback,
  expanded
}) => {
  const handleKeyDown = event => {
    if ([undefined, "ArrowDown", "ArrowUp", "Enter"].includes(event.key)) {
      event.preventDefault();
      callback(assetID);
    }
  };

  const iconClassName = classNames(
    "fa",
    { "fa-chevron-up": children.length > 0 && expanded },
    { "fa-chevron-down": children.length > 0 && !expanded },
    { "d-none": children.length === 0 }
  );

  return useMemo(
    () => (
      <li>
        <a href={pageURL}>
          <span>{pageName}</span>
        </a>
        <i
          className={iconClassName}
          onClick={() => callback(assetID)}
          onKeyDown={event => handleKeyDown(event)}
          tabIndex="0"
        ></i>
        {children.length > 0 && (
          <SubMenuGroup navData={children} menuOpen={expanded} />
        )}
      </li>
    ),
    [expanded]
  );
};

export default SubMenuItem;
