import React from "react";
import SubMenuGroup from "../hamburger-nav/sub-menu-group/SubMenuGroup";

class SecondaryNavMob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };

    this.toggleMenuOpen = this.toggleMenuOpen.bind(this);
  }

  toggleMenuOpen(e) {
    if (
      e.key === undefined ||
      e.key == "ArrowDown" ||
      e.key == "ArrowUp" ||
      e.key == "Enter"
    ) {
      e.preventDefault();
      this.setState(prevState => ({
        menuOpen: !prevState.menuOpen
      }));
    }
  }

  render() {
    if (
      window.location.href.indexOf("homepage") > -1 ||
      window.location.href.indexOf("news") > -1 ||
      this.props.data.active == false
    ) {
      return false;
    }
    return (
      <div id="secondary-nav-mob" className="d-lg-none">
        <strong onClick={this.toggleMenuOpen} onKeyDown={this.toggleMenuOpen}>
          {this.props.data.title}
          <i
            className={`fa ${
              this.state.menuOpen ? "fa-chevron-up" : "fa-chevron-down"
            }`}
            tabIndex="0"
          ></i>
        </strong>
        <span className="nav-border"></span>
        <SubMenuGroup
          menuOpen={this.state.menuOpen}
          navData={this.props.data.navigation}
        />
      </div>
    );
  }
}

export default SecondaryNavMob;
