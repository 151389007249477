import React from "react";
import Render from "../../utilities/Render";

class BackToTop extends React.Component {
  constructor(props) {
    super();
    window.scroll = () => {
      if (
        document.body.scrollTop > 600 ||
        document.documentElement.scrollTop > 600
      ) {
        this.openButton();
      } else {
        this.closeButton();
      }
    };
  }

  state = { status: "close" };

  openButton = event => {
    this.setState({ status: "open" });
  };

  closeButton = event => {
    this.setState({ status: "close" });
  };

  scrollToTop = event => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    event.preventDefault();
    this.closeButton();
  };

  render() {
    return (
      <div className={this.state.status + " back-to-top-wrapper print-hidden "}>
        <a href="#" className="back-to-top" onClick={this.scrollToTop}>
          <span className="visually-hidden">Back to top</span>
        </a>
      </div>
    );
  }
}

Render.Component("#back-to-top-react", null, BackToTop);

export default BackToTop;
