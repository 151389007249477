import React, { useEffect } from "react";

const SocialShare = ({ type }) => {
  const pageTitle = document
    .querySelector("title")
    .innerText.replace(" | Business Victoria", "");
  const pageUrl = window.location.href.replace(/\/_nocache|\/_recache/gi, "");
  const email = `mailto:?subject=Business Victoria Website Content: ${pageTitle}&body=Hi. I found some information that might interest you: ${pageTitle} - ${pageUrl}`;

  useEffect(() => {
    const addthisConfig = document.createElement("script");
    const addthisScript = document.createElement("script");

    addthisConfig.textContent = `var addthis_config = {
      ui_disable: true,
      services_expanded: "mailto,gmail,whatsapp,messenger,wechat,facebook,linkedin,twitter,print,link"
    }`;

    addthisScript.setAttribute(
      "src",
      "https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f4840af936c3fb5"
    );

    document.body?.appendChild(addthisConfig);
    document.body?.appendChild(addthisScript);
  }, []);

  const iconClass = name => `${name}${type !== "standard" ? "-mobile" : ""}`;

  return (
    <div id="social-share" className={type}>
      <ul className={type}>
        {/* <li>
          <a href={email}>
            <span
              className={iconClass("mail")}
              aria-label="email a friend"
            ></span>
          </a>
        </li>
        <li>
          <a href="javascript:window.print()" className=" ml-2">
            <span className={iconClass("print")} aria-label="print"></span>
          </a>
        </li> */}
        <li>
          <a role="button" className="addthis_button_compact">
            <span className={iconClass("share")} aria-label="share"></span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialShare;
