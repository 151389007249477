import React, { useState, useEffect } from "react";

const LinkBox = props => {
  useEffect(() => {
    var linkBoxRelatedMobileNode = document.getElementById(
      "linkbox-related-mobile"
    );
    if (
      props.view == "mobile" &&
      linkBoxRelatedMobileNode != null &&
      props.Data.quickLinks
    ) {
      // Declare a fragment:
      var fragment = document.createDocumentFragment();
      // Append desired element to the fragment:
      fragment.appendChild(document.querySelectorAll(".link-box.mobile")[0]);
      // Append fragment to desired element:
      linkBoxRelatedMobileNode.appendChild(fragment);
    }
  });

  return (
    <div className={"link-box " + props.view}>
      {props.Data.heading && (
        <span className="heading">{props.Data.heading}</span>
      )}

      {props.Data.description && <p>{props.Data.description}</p>}
      {props.Data.quickLinks && (
        <ul>
          {props.Data.quickLinks.map((item, index) =>
            item.pageName.length > 1 ? (
              <li key={index}>
                <a
                  href={item.url}
                  className="stretched-link"
                  target={
                    item.url.indexOf(window.location.host) > -1
                      ? "_self"
                      : "_blank"
                  }
                >
                  <span>{item.pageName}</span>

                  <span className="small chevron chevron-right ml-3"></span>
                </a>
              </li>
            ) : null
          )}
        </ul>
      )}

      {props.Data.buttonText && props.Data.buttonURL && (
        <a
          href={props.Data.buttonURL}
          target={
            props.Data.buttonURL.indexOf(window.location.host) > -1
              ? "_self"
              : "_blank"
          }
          className="button primary yellow"
        >
          {props.Data.buttonText}
        </a>
      )}
    </div>
  );
};

export default LinkBox;
