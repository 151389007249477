import React from "react";

function Breadcrumbs(props) {
  if (
    window.location.href.indexOf("homepage") > -1 ||
    !props.breadcrumbsData.displayBreadcrumbs
  ) {
    return false;
  } else {
    const breadcrumbStructuredData = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: props.breadcrumbsData.links.map((item, index, arr) => {
        if (index == arr.length - 1) {
          return {
            "@type": "ListItem",
            position: index + 1,
            name: item.pageName
          };
        } else {
          return {
            "@type": "ListItem",
            position: index + 1,
            name: item.pageName,
            item: item.pageURL
          };
        }
      })
    };

    return (
      <div className="breadcrumbs-social-container container-fluid">
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbStructuredData)}
        </script>
        <div className="row">
          <div className="col-12">
            <nav id="breadcrumbs">
              <ul>
                {props.breadcrumbsData.links.map((item, index, arr) => {
                  {
                    if (index == arr.length - 1) {
                      return <li key={index}>{item.pageName}</li>;
                    } else {
                      return (
                        <li key={index}>
                          <a href={item.pageURL}>{item.pageName}</a>
                        </li>
                      );
                    }
                  }
                })}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Breadcrumbs;
