import React from "react";
import Render from "../../utilities/Render";

const SkipToContent = props => {
  return (
    <div id="skip-to-content">
      <a className="skip-main" href="#main" tabIndex="1">
        Skip to main content
      </a>
    </div>
  );
};

Render.Component("#skip-to-content-react", null, SkipToContent);

export default SkipToContent;
