import React from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from "../components/error-boundary/ErrorBoundary";

const Render = () => {
  return <></>;
};

/**
 * Renders a single component within an element.
 * @param {string} querySelector
 *   Selector string passed to .querySelector to find the Node Element to mount
 *   the Component into.
 * @param {Object} data
 *   The data object passed to the Component.
 * @param {Component} Component
 *   The React Component to render.
 * @author Dan Walsh
 * @since 08/01/2021
 */
const Component = (querySelector, data, Component) => {
  const element = document.querySelector(querySelector);
  if (element) {
    ReactDOM.render(
      <ErrorBoundary>
        <Component data={data} />
      </ErrorBoundary>,
      element
    );
  }
};

/**
 * Renders a series of components targetted by a common query selector.
 * @param {string} querySelectorAll
 *   Selector string passed to .querySelectorAll() to generate a NodeList of
 *   all components to render on the page.
 * @param {string} dataArrayName
 *   Name of the global variable (as a string) containing the configuration and
 *   data for all components to be rendered. This is passed as a string and
 *   later retrieved from the window object as the Array may not be set at
 *   execution time.
 * @param {Component} Component
 *   The React component to render.
 * @param {string} assetIdAttribute
 *   The HTML attribute on each component `div` that stores the Matrx asset ID
 *   relating to the container container. The default value is `"data-assetid"`.
 * @author Dan Walsh
 * @since 09/12/2020
 */
const ComponentsByClass = (
  querySelectorAll,
  dataArrayName,
  Component,
  assetIdAttribute = "data-assetid"
) => {
  const elements = document.querySelectorAll(querySelectorAll);
  if (elements.length > 0) {
    if (!window[dataArrayName]) {
      console.error(
        `Unable to render components for selector ${querySelectorAll} as window[${dataArrayName}] is undefined.`
      );
      return;
    }
    for (let elementIndex = 0; elementIndex < elements.length; elementIndex++) {
      const assetId = elements[elementIndex].getAttribute(assetIdAttribute);
      const dataArray = window[dataArrayName];
      if (dataArray[assetId]) {
        ReactDOM.render(
          <ErrorBoundary>
            <Component data={dataArray[assetId]} />
          </ErrorBoundary>,
          elements[elementIndex]
        );
      } else {
        console.error(
          `Unable to render component index ${elementIndex} for selector ${querySelectorAll} as dataArray[${assetId}] is undefined.`
        );
      }
    }
  }
};

Render.Component = Component;
Render.ComponentsByClass = ComponentsByClass;

export default Render;
