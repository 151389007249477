import React from "react";
import Render from "../../utilities/Render";

const Footer = props => {
  return (
    <footer id="footer">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-12 col-md-6 footer-column vic-gov-logo-container">
            <a href="https://vic.gov.au" className="d-inline-block">
              {" "}
              <img
                src={require("../../images/vic-state-gov-logo.svg")}
                className="vic-gov-logo"
                alt="Victorian State Government logo"
              />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 footer-social-links">
            <p className="footer-site-name">
              {props.data.siteName}{" "}
              <a className="tel" href={"tel:" + props.data.phoneNumber}>
                {props.data.phoneNumber}
              </a>
            </p>
            <ul className="my-2">
              {props.data.social.map(({ link, tagline, icon }, index) => (
                <li key={index}>
                  <a href={link}>
                    <img
                      src={require("../../images/" + icon + "-icon.svg")}
                      alt={tagline}
                    />
                  </a>
                </li>
              ))}
            </ul>
            <div
              className="footer-services"
              dangerouslySetInnerHTML={{ __html: props.data.services }}
            ></div>
          </div>
          <div className="col-md-6 footer-quick-links">
            <h2>{props.data.quicklinkHeading}</h2>
            <div className="row">
              <div className="col-6 col-md-4">
                <ul>
                  {props.data.quickLinks1.map((item, index) => (
                    <li key={index}>
                      <a href={item.pageURL}>{item.pageName}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-6 col-md-8">
                <ul>
                  {props.data.quickLinks2.map((item, index) => (
                    <li key={index}>
                      <a href={item.pageURL}>{item.pageName}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-10 acknowledgements mt-3">
            <div className="row">
              <div className="col-lg-12">
                <img
                  src={require("../../images/aboriginal-flag.svg")}
                  height="40"
                  className="mr-3"
                  alt="Aboriginal flag"
                />
                <img
                  src={require("../../images/torres-strait-islanders-flag.svg")}
                  height="40"
                  className="mr-3"
                  alt="Torres Strait Islander flag"
                />
                <img
                  src={require("../../images/inclusion-flag.svg")}
                  height="40"
                  alt="Pride flag inclusion"
                />
              </div>
              <div className="col-lg-12">
                <div
                  className="first-peoples mt-3"
                  dangerouslySetInnerHTML={{ __html: props.data.firstPeoples }}
                ></div>
                <div
                  className="inclusion mt-2"
                  dangerouslySetInnerHTML={{ __html: props.data.inclusion }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="gov-text">State Government of Victoria</div>
          <div className="copyright-text">
            Copyright DJSIR {String.fromCharCode(169)}{" "}
            {new Date().getFullYear()}
          </div>
          <div className="page-last-updated">{props.data.pageUpdated}</div>
        </div>
      </div>
    </footer>
  );
};

Render.Component("#footer-react", footerDataCMS, Footer);

export default Footer;
