import React from "react";
import Render from "../../utilities/Render";

const Breakpoints = props => {
  return (
    <div>
      <div className="breakpoint sm">sm</div>
      <div className="breakpoint md">md</div>
      <div className="breakpoint lg">lg</div>
      <div className="breakpoint xl">xl</div>
    </div>
  );
};

Render.Component("#breakpoints-react", null, Breakpoints);

export default Breakpoints;
