import React, { useMemo, useState } from "react";
import SubMenuItem from "../sub-menu-item/SubMenuItem";

const SubMenuGroup = ({ navData, menuOpen }) => {
  const [expandedSubMenuItem, setExpandedSubMenuItem] = useState(null);

  const handleExpandMenu = assetID => {
    setExpandedSubMenuItem(assetID === expandedSubMenuItem ? null : assetID);
  };

  return useMemo(() => {
    if (!menuOpen) {
      return null;
    }
    return (
      <ul className="open">
        {navData.map(item => (
          <SubMenuItem
            navData={item}
            key={item.assetID}
            callback={handleExpandMenu}
            expanded={item.assetID === expandedSubMenuItem ? true : false}
          />
        ))}
      </ul>
    );
  }, [menuOpen, expandedSubMenuItem]);
};

export default SubMenuGroup;
