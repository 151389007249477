import "react-app-polyfill/ie11";
import "promise-polyfill/src/polyfill";
import "whatwg-fetch";

import "core-js/features/array";
import "core-js/features/string";
import "core-js/features/url-search-params";
import "unorm";

import "focus-within-polyfill";

import React from "react";
import ReactDOM from "react-dom";

import font from "./fonts/VIC-Regular.woff";
import bootstrap from "bootstrap";
import "./styles/main.scss";
import "font-awesome/css/font-awesome.min.css";

// load assets
function requireAll(r) {
  r.keys().forEach(r);
}
//requireAll(require.context('./data-files/', true));
requireAll(require.context("./fonts/", true));
requireAll(require.context("./images/", true));

// Global Components
import SkipToContent from "./components/skip-to-content/SkipToContent";
import Breakpoints from "./components/breakpoints/Breakpoints";
import Alerts from "./components/alerts/Alerts";
import Header from "./components/header/Header";
import BackToTop from "./components/back-to-top/BackToTop";
import Footer from "./components/footer/Footer";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";

/* Covid virtual assistant removed */
//require("./components/virtual-assistant/virtual-assistant");

//Dynamically loaded components

if (document.querySelector("#secondary-nav")) {
  import("./components/secondary-nav/SecondaryNav").then(() => {});
}

if (document.querySelector("#local-support-react")) {
  import("./components/local-support/LocalSupport").then(() => {});
}

if (document.querySelector("#business-stories-react")) {
  import("./components/business-stories/BusinessStories").then(() => {});
}

if (document.querySelectorAll(".accordion")[0]) {
  import("./components/accordion/Accordion").then(accordion => {
    accordion.default();
  });
}

if (document.querySelectorAll("main img")[0]) {
  import("./components/images/ImageCaptions").then(captions => {
    captions.default();
  });
}

if (document.querySelectorAll(".image-gallery").length > 0) {
  import("./components/image-gallery/ImageGallery").then(() => {});
}

if (document.querySelector("#page-summary-react")) {
  import("./components/page-summary/PageSummary").then(() => {});
}

if (document.querySelector("#latest-updates-react")) {
  import("./components/latest-updates/LatestUpdates").then(() => {});
}

if (document.querySelector("#featured-content-react")) {
  import("./components/featured-content-module/FeaturedContent").then(() => {});
}

if (document.querySelector("#tools-react")) {
  import("./components/tools/Tools").then(() => {});
}

if (document.querySelector("#grants-listing-react")) {
  import("./components/grants/Listing").then(() => {});
}

if (document.querySelector("#grant-summary")) {
  import("./components/grants/Parent").then(() => {});
}

if (document.querySelector("#package-grants-list")) {
  import("./components/grants/Package").then(() => {});
}

if (document.querySelector("#subscribe-banner-react")) {
  import("./components/subscribe-banner/SubscribeBanner").then(() => {});
}

if (document.querySelector("#related-links-react")) {
  import("./components/related-links/RelatedLinks").then(() => {});
}

if (document.querySelector("#helpful-feedback-react")) {
  import("./components/helpful-feedback/HelpfulFeedback").then(() => {});
}

if (document.querySelector("#guide-react")) {
  import("./components/guide/Guide").then(() => {});
}

if (document.querySelector("#landing-page-react")) {
  import("./components/landing-page/LandingPage").then(() => {});
}

if (document.querySelector("#sublanding-page-react")) {
  import("./components/sublanding-page/SubLandingPage").then(() => {});
}

if (document.querySelectorAll("main table")) {
  import("./components/tables/Tables").then(tables => {
    tables.default();
  });
}

if (document.querySelector("#news-listing-react")) {
  import("./components/news-listing/NewsListing").then(() => {});
}

if (document.querySelectorAll(".card-group-react")[0]) {
  import("./components/card-group/CardGroup").then(() => {});
}

if (document.querySelector("#global-search-react")) {
  import("./components/global-search/GlobalSearch").then(() => {});
}

if (document.querySelector("#sessionTimes-react")) {
  import("./components/event/EventSessionTimes").then(() => {});
}

if (document.querySelector("#events-react")) {
  import("./components/events/Events").then(() => {});
}

if (document.querySelectorAll(".content-feature-container").length > 0) {
  import("./components/content-feature/ContentFeature").then(() => {});
}

if (document.querySelector("#help-react")) {
  import("./components/help/Help").then(() => {});
}

if (document.querySelectorAll(".link-module-react")[0]) {
  import("./components/link-module/LinkModule").then(() => {});
}

if (document.querySelectorAll(".highlight-module-react")[0]) {
  import("./components/highlight-module/HighlightModule").then(() => {});
}

if (document.querySelectorAll(".callout-module-react")[0]) {
  import("./components/highlight-module/HighlightModule").then(() => {});
}

if (document.querySelector("#courses-listing-react")) {
  import("./components/courses/Listing").then(() => {});
}

if (document.querySelector("#faces-case-studies-listing-react")) {
  import("./components/faces-case-studies/Listing").then(() => {});
}

if (document.querySelector("#hub-articles-listing-react")) {
  import("./components/hub-articles/Listing").then(() => {});
}

if (document.querySelector("#related-links-cards-react")) {
  import("./components/related-links-cards/RelatedLinksCards").then(() => {});
}

//Social share component
import SocialShare from "./components/social-share/SocialShare";
if (document.querySelector("#social-share-react")) {
  ReactDOM.render(
    <ErrorBoundary>
      <SocialShare type={"standard"} />
    </ErrorBoundary>,
    document.getElementById("social-share-react")
  );
}

//Temporary bandaid solution for specific workshop event
const locateEvent = window.location.href.indexOf("E23356") > 0;

if (locateEvent == true) {
  document.querySelectorAll(
    ".grant-summary-info > div:first-of-type > p"
  )[0].innerHTML = "Ongoing";
} // To be deleted
