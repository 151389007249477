import React from "react";
import Render from "../../utilities/Render";

var API;
if (window.location.href.indexOf("localhost") > -1) {
  var API = "../src/components/alerts/alerts-data.json";
} else {
  var API = "https://business.vic.gov.au/_designs/data-files/alerts.json";
}

class Alerts extends React.Component {
  constructor(props) {
    super();
    this.state = {
      alertState: "disabled"
    };
  }

  componentDidMount() {
    fetch(API)
      .then(response => response.json())
      .then(data => {
        this.setState({
          alertState: data.alertState,
          openClosed: data.openClosed,
          alertLevel: data.alertLevel,
          heading: data.heading,
          alert: data.alert,
          url: data.url,
          viewall: data.viewall,
          viewallurl: data.viewallurl
        });

        if (document.cookie.indexOf("bvalerts=1") > -1) {
          this.setState({
            openclosed: "open"
          });
        } else {
          this.setState({
            openclosed: "closed"
          });
        }
      });
  }

  close = () => {
    this.setState({
      openclosed: "open"
    });
    var d = new Date();
    document.cookie =
      "bvalerts=1; expires=" + d.getTime() + 31556952 + "path=/;";
  };

  open = () => {
    this.setState({
      openclosed: "closed"
    });
    var d = new Date();
    document.cookie = "bvalerts=1 ; expires =" + d.toGMTString() + "path=/;";
  };

  render() {
    return (
      <div>
        <div
          id="alerts"
          className={
            this.state.alertState +
            " " +
            this.state.openclosed +
            " " +
            this.state.alertLevel
          }
        >
          <div className="container-fluid alerts-container">
            <div className="row">
              <div className="safety-alerts-heading">
                <span className="alert-heading">{this.state.heading}: </span>{" "}
                <a href={this.state.url}>{this.state.alert}</a>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="fa fa-times close-button"
            aria-label="Close"
            onClick={this.close}
          ></button>
        </div>
        <div className={this.state.openclosed + " open-alert"}>
          <button
            className={"open-alert-icon" + " " + this.state.alertLevel}
            aria-label="Open alert"
            onClick={this.open}
          ></button>{" "}
        </div>
      </div>
    );
  }
}

Render.Component("#alerts-react", null, Alerts);

export default Alerts;
