import React from "react";
import LinkBox from "./linkbox/Linkbox";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import SecondaryNavMob from "../secondary-nav-mob/SecondaryNavMob";
import SocialShare from "../social-share/SocialShare";

const Heading = props => {
  return (
    <section id="heading">
      <Breadcrumbs breadcrumbsData={props.breadcrumbsData} />
      {document.querySelector("#social-share-react") && (
        <SocialShare type="mobile" />
      )}
      {props.secondaryNavMobData.active != false && (
        <SecondaryNavMob data={props.secondaryNavMobData} />
      )}
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6  order-2 order-md-1 main-heading">
            <h1>{props.Data.heading}</h1>
            {props.Data.description && <p>{props.Data.description}</p>}
          </div>

          {/*Iteration 1 - Image*/}
          {props.Data.type == "image" && (
            <div className="col-sm col-md-6 image order-1 order-md-2 ">
              <div
                id="homepage-banner-image"
                style={{ backgroundImage: "url(" + props.Data.image + ")" }}
              >
                <div className="image-rectangle"></div>
                <div
                  className="caption"
                  dangerouslySetInnerHTML={{ __html: props.Data.caption }}
                />
              </div>
            </div>
          )}

          {/*Iteration 2 - Linkbox*/}
          {props.Data.type == "linkbox" && (
            <div className="col-sm col-md-6 image order-2">
              <LinkBox Data={props.Data.linkBox} view={"desktop"} />

              {props.Data.linkBox.quickLinks && (
                <LinkBox Data={props.Data.linkBox} view={"mobile"} />
              )}
            </div>
          )}

          {/*Iteration none - Fallback*/}
          {props.Data.type == "none" && (
            <div className="col-sm col-md-6 image order-2">&nbsp;</div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Heading;
