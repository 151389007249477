import React from "react";
import SubMenuGroup from "./sub-menu-group/SubMenuGroup";
import classNames from "classnames";

const HamburgerNav = ({ navData, hamburgerOpen }) => {
  const className = classNames("container hamburger-nav d-md-none", {
    open: hamburgerOpen
  });

  return (
    <div className={className}>
      <SubMenuGroup navData={navData} menuOpen={hamburgerOpen} />
    </div>
  );
};

export default HamburgerNav;
