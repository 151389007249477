import React from "react";

class PrimaryNavigationItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasChildren: this.props.navItem.children.length > 0,
      menuOpen: false
    };

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  openMenu() {
    this.props.updateParent(true);
    //if globalMenuExpanded is false (meaning none of the megamenus are open), then add a slight delay before expanding
    if (this.props.globalMenuExpanded == false) {
      setTimeout(() => {
        if (this.props.globalMenuExpanded == true) {
          this.setState({
            menuOpen: true
          });
        }
      }, 300);
    } else {
      this.setState({
        menuOpen: true
      });
    }
  }

  closeMenu() {
    this.props.updateParent(false);
    this.setState({
      menuOpen: false
    });
    //menuOpen state is set to false immediately, but also after 300ms to solve a bug where a user quickly moves their mouse across multiple top level nav items and multiple megamenus remain open
    setTimeout(() => {
      this.setState({
        menuOpen: false
      });
    }, 300);
  }

  keyboardMenuFocus = e => {
    if (e.key == "ArrowDown") {
      this.setState({
        menuOpen: true
      });
    }

    if (e.key == "ArrowUp") {
      this.setState({
        menuOpen: false
      });
    }

    var primaryNavTabs = document.querySelectorAll(".primary-nav li");
    var lastTabItem = primaryNavTabs[
      primaryNavTabs.length - 1
    ].querySelectorAll("a")[0];

    if (e.key == "Tab" && lastTabItem == e.target) {
      this.setState({
        menuOpen: false
      });
    }
  };

  getUniqueCategoryCount(index) {
    const categories = new Set(index.map(index => index.category));

    return categories.size;
  }

  outputChildren(index) {
    if (index.length > 0) {
      //separate children into categories
      //note that we start the cat arrays at cat0 in order to avoid confusion as arrays begin at 0, not 1
      var categories = [];
      var cat0 = [];
      var cat1 = [];
      var cat2 = [];
      index.map(index => {
        //check if fits in existing category
        if (categories.indexOf(index.category) > -1) {
          //check index of category which corresponds to each array
          switch (categories.indexOf(index.category)) {
            case 0:
              cat0.push(index);
              break;
            case 1:
              cat1.push(index);
              break;
            case 2:
              cat2.push(index);
              break;
            default:
              return;
          }
        } else {
          //add new category
          switch (categories.push(index.category)) {
            case 1:
              cat0.push(index);
              break;
            case 2:
              cat1.push(index);
              break;
            case 3:
              cat2.push(index);
              break;
            default:
              return;
          }
        }
      });

      return (
        <div className="row">
          {cat0.length > 0 && (
            <div className={categories.length == 1 ? "col-12" : "col-4"}>
              {categories[0] && <strong>{categories[0]}</strong>}
              <ul>
                {cat0.map(index => (
                  <li key={index.assetID}>
                    <a href={index.pageURL}>
                      <span>{index.pageName}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {cat1.length > 0 && (
            <div className="col-4">
              {categories[1] && <strong>{categories[1]}</strong>}
              <ul>
                {cat1.map(index => (
                  <li key={index.assetID}>
                    <a href={index.pageURL}>
                      <span>{index.pageName}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {cat2.length > 0 && (
            <div className="col-4">
              {categories[2] && <strong>{categories[2]}</strong>}
              <ul>
                {cat2.map(index => (
                  <li key={index.assetID}>
                    <a href={index.pageURL}>
                      <span>{index.pageName}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      );
    } else {
      return false;
    }
  }

  render() {
    return (
      <li
        className={`col-3 ${this.state.menuOpen ? "open" : "closed"} ${
          this.props.navItem.topMenu ? "d-none" : ""
        } ${
          this.state.hasChildren &&
          this.getUniqueCategoryCount(this.props.navItem.children) == 1
            ? "primary-nav__col--single"
            : ""
        }`}
        key={this.props.navItem.assetID}
        onMouseEnter={this.openMenu}
        onMouseLeave={this.closeMenu}
        onKeyDown={e => this.keyboardMenuFocus(e)}
      >
        <a href={this.props.navItem.pageURL}>
          <span>{this.props.navItem.pageName}</span>
          {this.state.hasChildren && <i className="fa fa-chevron-down"></i>}
        </a>
        {this.state.hasChildren &&
          this.outputChildren(this.props.navItem.children)}
      </li>
    );
  }
}

export default PrimaryNavigationItem;
