import React from "react";

const TopMenu = ({navItem}) => {
  return (
            <li key={navItem.assetID} className={navItem.topMenu ? "" : "d-none"}>
              <a href={navItem.pageURL}>
                <span>{navItem.pageName}</span>
              </a>
            </li>
  );
}

export default TopMenu;